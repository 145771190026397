import api from '../api/api';

/**
 * Fetches user information from the Rails app using the provided userId.
 * @param {string} userId - The ID of the user to fetch information for.
 * @returns {Promise<object>} - A promise that resolves to the user information.
 */
const fetchUserInfo = async (userId) => {
  try {
    const response = await api.get(`/players/${userId}/basic_info`);
    return response.data;
  } catch (error) {
    console.error('Error fetching user information:', error);
    throw error;
  }
};

export default fetchUserInfo;