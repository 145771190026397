import React, { useState, useEffect } from 'react';
import { Box, Heading, Table, Thead, Tbody, Tr, Th, Td } from '@chakra-ui/react';
import api from '../api/api';

const Commodities = () => {
  const [commodities, setCommodities] = useState([]);

  useEffect(() => {
    fetchCommodities();
  }, []);

  const fetchCommodities = async () => {
    try {
      const response = await api.get('/commodities/current_prices');
      setCommodities(response.data);
    } catch (error) {
      console.error('Error fetching commodities:', error);
    }
  };

  return (
    <Box bg="#FFFFFF" height="80vh" maxHeight="80vh" textAlign="center" py={10} px={6} overflowY="auto">
  <Heading as="h1" size="xl" mb={5}>
    Commodities
  </Heading>
  <Table variant="simple">
    <Thead>
      <Tr>
        <Th>Name</Th>
        <Th isNumeric>Current Price</Th>
        <Th isNumeric>Yesterday's Price</Th>
        <Th isNumeric>Change (%)</Th>
      </Tr>
    </Thead>
    <Tbody>
      {commodities.map((commodity) => {
        const yesterdayPrice = commodity.price_history[commodity.price_history.length - 2]?.price;
        const currentPrice = Number(commodity.current_price);
        const percentageChange = ((currentPrice - yesterdayPrice) / yesterdayPrice) * 100;

        return (
          <Tr key={commodity.id}>
            <Td>{commodity.name}</Td>
            <Td isNumeric>${currentPrice.toFixed(3)}</Td>
            <Td isNumeric>${Number(yesterdayPrice).toFixed(3)}</Td>
            <Td isNumeric style={{ color: percentageChange >= 0 ? 'green' : 'red' }}>
  {percentageChange.toFixed(2)}%
</Td>
          </Tr>
        );
      })}
    </Tbody>
  </Table>
</Box>
  );
};

export default Commodities;