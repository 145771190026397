/**
 * Splits a message into smaller sub-messages based on punctuation.
 * @param {string} message - The message to be split.
 * @returns {string[]} - An array of sub-messages.
 */
const splitMessage = (message) => {
  return message.split(/(?<=[.!?])\s+/).filter(Boolean);
};

export default splitMessage;
