import React, { useState, useEffect } from 'react';
import { Box, Heading, List, ListItem, Text, Divider, Button } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import api from '../api/api';

const ChatGptPrompts = () => {
  const [prompts, setPrompts] = useState([]);

  useEffect(() => {
    const fetchPrompts = async () => {
      try {
        const response = await api.get('/chat_gpt_prompts');
        setPrompts(response.data);
      } catch (error) {
        console.error('Error fetching ChatGPT prompts:', error);
      }
    };

    fetchPrompts();
  }, []);

  return (
    <Box textAlign="center" py={10} px={6} height="80vh" maxHeight="80vh" >
      <Heading as="h1" size="xl" mb={5}>
        ChatGPT Prompts
      </Heading>
      <List spacing={5}>
        {prompts.map((prompt) => (
          <React.Fragment key={prompt.id}>
            <ListItem mb={5}>
              <Text fontWeight="bold" fontSize="xl" textAlign="left">{prompt.name}</Text>
              <Button as={Link} to={`/admin/chat_gpt_prompts/edit/${prompt.id}`} colorScheme="teal" mt={2} mb={2}>
                Edit
              </Button>
              <Text textAlign="left">{prompt.prompt_text}</Text>
            </ListItem>
            <Divider />
          </React.Fragment>
        ))}
      </List>
    </Box>
  );
};

export default ChatGptPrompts;