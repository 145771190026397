import api from '../api/api';

/**
 * Replaces placeholders in a message with the provided values.
 * @param {string} message - The message containing placeholders.
 * @param {object} values - An object containing the values to replace the placeholders.
 * @param {string} fullPlayerName - The full player name to be used for generating the cover story.
 * @returns {Promise<{ result: string, familyName?: string, quiz: string, facts: string, narrative: string }>} - The message with placeholders replaced and the family name if generated.
 */
const replacePlaceholders = async (message, values, fullPlayerName) => {
  let result = message;
  let familyName;
  let password;
  let quiz;
  let narrative;
  let facts;

  for (const [key, value] of Object.entries(values)) {
    console.log(`Processing placeholder: [${key}] with value: ${value}`);
    
    if (key === 'playername') {
      try {
        console.log('Sending request to generate_name endpoint with first_name:', value);
        const response = await api.post('/generate_name', { first_name: value });
        console.log('Received response from generate_name:', response.data);
        familyName = response.data.family_name;
        result = result.replace('[playername]', `${value}`);
        result = result.replace('[suggestedname]', `${familyName}`);
        fullPlayerName = `${value} ${familyName}`; // Update fullPlayerName
      } catch (error) {
        console.error('Error generating name:', error);
      }
    } else if (key === 'coverstory') {
      try {
        console.log('Sending request to generate_cover_story endpoint with context:', fullPlayerName);
        const response = await api.post('/generate_cover_story', { player_name: fullPlayerName });
        console.log('Received response from generate_cover_story:', response.data);

        let responseData = response.data;
        
        // If responseData is an object, stringify it first
        if (typeof responseData !== 'string') {
          responseData = JSON.stringify(responseData);
        }

        // Clean the response to remove ```json and ``` and parse the nested JSON
        const cleanedResponse = responseData.replace(/```json|```/g, '').replace(/\\"/g, '"').replace(/\\n/g, '').replace(/\\t/g, '').replace(/^"\{/, '{').replace(/\}"$/, '}');
        console.log('Cleaned response:', cleanedResponse);

        const parsedJson = JSON.parse(cleanedResponse);

        console.log('Parsed JSON object:', parsedJson);
        

        // Extract the narrative from the parsed response
        const coverStory = parsedJson.parsed_cover_story.narrative;
        narrative = coverStory;
        facts = parsedJson.parsed_cover_story.facts;
        quiz = parsedJson.parsed_cover_story.quiz;

        console.log('Parsed narrative:', coverStory);

        result = result.replace('[coverstory]', coverStory);
      } catch (error) {
        console.error('Error generating cover story:', error);
      }
    } else {
      const regex = new RegExp(`\\[${key}\\]`, 'g');
      result = result.replace(regex, value);
    }
  }

  return { result, familyName, quiz, facts, narrative };
};

export default replacePlaceholders;