import React, { useState, useEffect } from 'react';
import { Box, Heading, Input, Button, Textarea } from '@chakra-ui/react';
import { useParams, useNavigate } from 'react-router-dom';
import api from '../api/api';

const EditChatGptPrompt = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [prompt, setPrompt] = useState({ name: '', prompt_text: '' });

  useEffect(() => {
    const fetchPrompt = async () => {
      try {
        const response = await api.get(`/chat_gpt_prompts/${id}`);
        setPrompt(response.data);
      } catch (error) {
        console.error('Error fetching prompt:', error);
      }
    };

    fetchPrompt();
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPrompt((prev) => ({ ...prev, [name]: value }));
  };

  const handleSave = async () => {
    try {
      await api.put(`/chat_gpt_prompts/${id}`, { chat_gpt_prompt: prompt });
      navigate('/admin/chat_gpt_prompts');
    } catch (error) {
      console.error('Error updating prompt:', error);
    }
  };

  return (
    <Box textAlign="center" py={10} px={6}  height="80vh" maxHeight="80vh" >
      <Heading as="h1" size="xl" mb={5}>
        Edit ChatGPT Prompt
      </Heading>
      <Input
        name="name"
        value={prompt.name}
        onChange={handleChange}
        placeholder="Prompt Name"
        mb={3}
      />
      <Textarea
        name="prompt_text"
        value={prompt.prompt_text}
        onChange={handleChange}
        placeholder="Prompt Text"
        mb={3}
        resize="vertical"
        minHeight="150px"
      />
      <Button colorScheme="teal" onClick={handleSave}>
        Save
      </Button>
    </Box>
  );
};

export default EditChatGptPrompt;