import React, { useEffect, useState } from 'react';
import { Box, Text, keyframes } from '@chakra-ui/react';

// Define keyframes for the animation
const move = keyframes`
  0% { transform: translateX(0); }
  50% { transform: translateX(50%); }
  100% { transform: translateX(100%); }
`;

const flicker = keyframes`
  0%, 100% { opacity: 1; }
  50% { opacity: 0.5; }
`;

const ConnectionAnimation = ({ onComplete }) => {
  const [step, setStep] = useState(0);

  useEffect(() => {
    if (step < 6) {
      const randomDelay = Math.floor(Math.random() * 2000) + 1000; // Random delay between 1000ms and 3000ms
      const interval = setInterval(() => {
        setStep((prevStep) => prevStep + 1);
      }, randomDelay);

      return () => clearInterval(interval);
    } else {
      onComplete();
    }
  }, [step, onComplete]);

  return (
    <Box textAlign="left" py={0} px={0}>
      <Text fontSize="lg" mb={5}>
        {step === 0 && "Initializing connection protocol..."}
        {step === 1 && "Resolving Nexus external address..."}
        {step === 2 && "Establishing security handshake..."}
        {step === 3 && "Encrypting data stream..."}
        {step === 4 && "Authenticating credentials..."}
        {step === 5 && "Verifying session integrity..."}
        {step === 6 && (
          <>
            <Box
              as="span"
              display="inline-block"
              bg="green.500"
              borderRadius="50%"
              width="10px"
              height="10px"
              mr={2}
              animation={`${flicker} 1s infinite`}
            ></Box>
            Connection established! Welcome to Paralux Nexus
          </>
        )}
      </Text>
      {step < 6 && (
        <Box
          display="inline-block"
          height="2px"
          width="200px"
          bg="teal"
          animation={`${move} 3s linear infinite`}
        ></Box>
      )}
    </Box>
  );
};

export default ConnectionAnimation;
