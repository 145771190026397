/**
 * Calculates the delay based on the length of the message.
 * This simulates the time it would take for a person to type the message.
 * @param {string} message - The message to calculate the delay for.
 * @returns {number} - The delay in milliseconds.
 */
const calculateDelay = (message) => {
    const wordsPerMinute = 180;
    const words = message.split(' ').length;
    const delay = (words / wordsPerMinute) * 60 * 1000; // convert minutes to milliseconds
    return delay;
  };
  
  export default calculateDelay;
  