import React from 'react';
import { Box, Container, Flex, Heading, Link } from '@chakra-ui/react';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { all } from '@awesome.me/kit-0487d53ec0/icons'; // Update the import to match your icon library
import { Link as RouterLink } from 'react-router-dom';

library.add(...all);

const Layout = ({ children }) => {
  return (
    <Box>
      {/* Header */}
      <Box as="header" bg="black" color="white" p={4}>
        <Flex justify="space-between" align="center">
          <Heading as="h1" size="lg" color="yellow.500">
          Paralux Nexus v1.7.2
          </Heading>
        </Flex>
      </Box>

      {/* Main Content Area with Side Columns */}
      <Container maxW="container.xl" mt={4}>
        <Flex>
          {/* Left Column */}
          <Box w={{ base: '0', md: '15%' }} bg="blue.700" p={4} color="yellow.500" display={{ base: 'none', md: 'block' }}>
            {/* Left Column Content */}
            <Box mb={4}>
              <FontAwesomeIcon icon={['fas', 'chart-line']} size="xl" />
            </Box>
          </Box>

          {/* Main Content */}
          <Box w={{ base: '100%', md: '70%' }} p={4}>
            {children}
          </Box>

          {/* Right Column */}
          <Box w={{ base: '0', md: '15%' }} align="center" bg="purple.700" p={4} color="yellow.500" display={{ base: 'none', md: 'block' }}>
            {/* Right Column Content */}
            <Box mb={4}>
                <FontAwesomeIcon icon={['far', 'newspaper']} size="xl" />
            </Box>
            <Box mb={4}>
                <FontAwesomeIcon icon={['fas', 'chart-line']} size="xl" />
            </Box>
          </Box>
        </Flex>
      </Container>
    </Box>
  );
};

export default Layout;
