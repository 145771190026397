// src/components/AdminSection.js
import React from 'react';
import { Link } from 'react-router-dom';

const AdminSection = () => (
  <div>
    <h1>Admin Section</h1>
    <nav>
      <ul>
        <li><Link to="/admin/characters">Characters</Link></li>
        <li><Link to="/admin/chats">Chats</Link></li>
        <li><Link to="/admin/messages">Messages</Link></li>
        <li><Link to="/admin/commodities">Commodities</Link></li>
        <li><Link to="/admin/chat_gpt_prompts">Chat GPT Prompts</Link></li>
        <li><Link to="/admin/news_narratives">News Narratives</Link></li>
        <li><Link to="/admin/news">News</Link></li>
        <li><Link to="/admin/players">Players</Link></li>
      </ul>
    </nav>
  </div>
);

export default AdminSection;
