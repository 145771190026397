import React, { useEffect, useState } from 'react';
import { Box } from '@chakra-ui/react';
import { jwtDecode } from 'jwt-decode';

import fetchUserInfo from '../utils/fetchUserInfo';

const Console = () => {
  const [userInfo, setUserInfo] = useState(null);
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem('nexus_login_token');
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        setUserId(decodedToken.sub);
      } catch (error) {
        console.error("Invalid token:", error);
        // Optionally, clear the invalid token from localStorage
        localStorage.removeItem('nexus_login_token');
      }
    } else {
      console.error('No token found');
    }
  }, []);
  

  useEffect(() => {
    if (userId) {
      const fetchUserData = async () => {
        try {
          const data = await fetchUserInfo(userId);
          setUserInfo(data);
        } catch (error) {
          console.error('Failed to fetch user information:', error);
        }
      };

      fetchUserData();
    }
  }, [userId]);

  return (
    <Box bg="#FFFFFF" height="80vh" maxHeight="80vh" textAlign="center" py={10} px={6}>
      <h1>Nexus Console</h1>
      <p>Welcome to the Nexus Console!</p>
      {/* Display user information */}
      {userInfo && (
        <div>
          <h2>User Information</h2>
          <p>Name: {userInfo.name}</p>
          <p>Email: {userInfo.email}</p>
          {/* Add more user fields as needed */}
        </div>
      )}
    </Box>
  );
};

export default Console;
