import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

const ProtectedRoute = ({ children }) => {
  const token = localStorage.getItem('nexus_login_token');

  if (!token) {
    console.log('No token found, redirecting to login');
    return <Navigate to="/login" />;
  }

  console.log('Token found, rendering protected route: ' + token);
  return children ? children : <Outlet />;
};

export default ProtectedRoute;
