import React from 'react';
import quotes from '../data/quotes.json';
import { Box, Flex, Heading, Link } from '@chakra-ui/react';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { all } from '@awesome.me/kit-0487d53ec0/icons'; // Update the import to match your icon library
import { Link as RouterLink } from 'react-router-dom';

library.add(...all);

const getRandomQuote = () => {
  const randomIndex = Math.floor(Math.random() * quotes.length);
  return quotes[randomIndex];
};

const NexusLayout = ({ children }) => {
  const token = localStorage.getItem('nexus_login_token') || null; // Ensure token is null if not set

  return (
    <Box height="100vh" bg="#071726" >
      {/* Header */}
      <Box as="header" bg="#071726" color="#F2F2F2" p={4}>
        <Flex justify="space-between" align="center">
          <Heading as="h1" size="lg" color="#3088BF">
          Paralux Nexus v27.7.7
          </Heading>
          {token !== null && ( // Only show links if the user is logged in
            <Flex align="center">
              <Link as={RouterLink} to="/admin" mr={4}>
                <FontAwesomeIcon icon={['fas', 'crown']} />
              </Link>
              <Link as={RouterLink} to="/console">
                <FontAwesomeIcon icon={['fas', 'house']} />
              </Link>
            </Flex>
          )}
        </Flex>
      </Box>

      {/* Main Content Area with Side Columns */}
      <Box maxW="100%">
        <Flex flexGrow={1}>
          {/* Left Column */}
          <Box w={{ base: '0', md: '25%' }} bg="#1C588C" p={4} color="#F2F2F2" display={{ base: 'none', md: 'block' }}>
            {/* Left Column Content */}
            <Box mb={4}>
              {(() => {
                const { quote, author } = getRandomQuote();
                return (
                  <Box>
                    <p>"{quote}"</p>
                    <p>- {author}</p>
                  </Box>
                );
              })()}
            </Box>
          </Box>

          {/* Main Content */}
          <Box w={{ base: '100%', md: '60%' }}>
            {children}
          </Box>

          {/* Right Column */}
          
            <Box w={{ base: '0', md: '15%' }} align="center" bg="#113859" p={4} color="#F2F2F2" display={{ base: 'none', md: 'block' }}>
              {/* Right Column Content */}
              {token && ( // Only show right column content if the user is logged in
                <><Box mb={4}>
                <Link as={RouterLink} to="/news">
                  <FontAwesomeIcon icon={['far', 'newspaper']} size="xl" />
                </Link>
              </Box><Box mb={4}>
                  <Link as={RouterLink} to="/commodities">
                    <FontAwesomeIcon icon={['fa', 'pickaxe']} size="xl" />
                  </Link>
                </Box></>
              )}
            </Box>
        </Flex>
      </Box>
    </Box>
  );
};

export default NexusLayout;