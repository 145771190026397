import React from 'react';
import { Box, keyframes } from '@chakra-ui/react';

// Define keyframes for the typing animation
const blink = keyframes`
  0% { opacity: 0; }
  50% { opacity: 0.5; }
  100% { opacity: 1; }
`;

/**
 * TypingAnimation component to simulate typing effect.
 * @returns {JSX.Element} - The TypingAnimation component.
 */
const TypingAnimation = () => (
  <Box display="flex" alignItems="center" justifyContent="center" width="50px" height="50px" bg="white" borderRadius="50%">
    {[...Array(3)].map((_, i) => (
      <Box
        key={i}
        width="8px"
        height="8px"
        bg="gray.300"
        borderRadius="50%"
        mx="2px"
        animation={`${blink} 1s infinite ${i * 0.3}s`}
      />
    ))}
  </Box>
);

export default TypingAnimation;
